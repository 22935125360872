<template>
	<div class="padding-top-10 edit-contain" element-loading-text="努力上传中,请稍后.." v-loading.fullscreen.lock="fullscreenLoading">
		<top :bgWhite='true'></top>
		<form enctype="multipart/form-data" name="fileinfo" id="myForm">
			<div class="contents  padding-bottom-20 ">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/my' }">个人中心</el-breadcrumb-item>
					<el-breadcrumb-item>编辑电子书</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="bg-white margin-top-20 padding-lr-50">
					<div class="  padding-top-20 fs24  padding-bottom-15">
						{{$route.query.aid ? '作品设置' : '创建新书'}}
					</div>
					<input type="text" @keyup.enter="login"
						maxlength="10"
						class="input line-eee w100 padding-lr-20 padding-tb-11 radius-8" v-model="form.title"
						placeholder="请输入书名" />
					<!-- <div class="solid-bottom padding-bottom-30 margin-top-20">
						<div class="  padding-top-20 fs24  padding-bottom-15">
							上传电子书
							<span class="text-red fs12 text-theme">(仅支持txt文件)</span>
						</div>
						<el-upload ref="my-upload" class="upload-demo" action=""  :auto-upload="false"
							:on-change="handleChange"  :on-remove="handleRemove">
							<img v-if="form.file==''" src="../../assets/image/center/add_book.png"
								class="width156 height126">
						</el-upload>
					</div> -->
					<div class="solid-bottom padding-bottom-30 margin-top-20">
						<div class="  padding-top-20 fs24  padding-bottom-15">
							请输入简介
						</div>
						 <el-input type="textarea" rows="6" v-model="form.body"  placeholder="请使出全力,吸引读者吧"></el-input>
					</div>
					<div class=" padding-bottom-30 solid-bottom margin-top-30">
						<div class="fs24  padding-bottom-15">
							封面选择
						</div>
						
						<el-form ref="form" :model="form">
							<el-form-item label="">
								<el-radio-group v-model="cover_type" @change="coverChange">
									<el-radio :label="1">免费封面</el-radio>
									<el-radio :label="2">人工设计</el-radio>
									<el-radio :label="3">手动上传</el-radio>
								</el-radio-group>
							</el-form-item>
							<div class="">
								<div class="flex align-center padding-bottom-10" v-if="cover_type==2">
									<img src="../../assets/image/center/phone.png" class="block-20 margin-right-5">
									<span>人工封面请使用微信扫码联系客服</span>
									<!-- <span class="padding-left-15  text-theme">联系方式：{{servive_mobile || '获取失败'}}</span> -->
								</div>
								<div class="flex align-center margin-bottom-15" v-if="cover_type==2">
									<img src="../../assets/image/wechat.jpg" class="block-200">
									<!-- <input type="" class="line-eee  padding-lr-10 code_input" style="width: 260px;"
										v-model="exchange_code" placeholder="请输入封面兑换码" />
									<span class="bg-e54f text-white  point padding-lr-10 confirm-btn"
										@click="coverInput_confirm">确认</span> -->
								</div>
							</div>
							<div class="flex align-center flex-wrap" v-if="cover_type==1">
								<div v-for="(item,index) in coverimglist" :key='index'>
									<div class="bg-f6f7 width118 height166 margin-right-16 cover_box margin-bottom-sm"
										@click="checked_cover(item,index)">
										<img :src="item.img_url" class="width118 height166 "
											style="border-radius: 8px 0px 0px 8px;">
										<img src="../../assets/image/checked.png" class="checked_img"
											v-if="current_cover==index" />
									</div>
								</div>
							</div>
							<div class="" v-if="cover_type==3">
								<el-upload class="avatar-uploader"  :action="BaseUrl+'/api/v1/5fc615f7da92b'"
									:show-file-list="false" :on-success="handleAvatarSuccess">
									<el-image v-if="upload_cover" :src="upload_cover" fit="cover" class="coverDiv_inner"></el-image>
									<div class="" v-else>
										<div class="coverDiv" >
											<div class="coverDiv_inner">
												<h1 class="text-center text-theme text-xl"><i class="el-icon-upload"></i></h1>
												<p class="text-center text-bold">上传封面</p>
												<!-- <p class="text-center text-bold margin-top-sm">建议尺寸：<br>270*380px</p> -->
											</div>
											<div class="cover">
											</div>
										</div>
									</div>
								</el-upload>
								<p class="text-sm text-theme margin-top-sm">温馨提示：请确认您对上传的图片享有拥有完全的版权或版权人授权许可，一旦造成侵权，本平台不负任何法律责任</p>
							</div>
						</el-form>
					</div>
					<div class=" padding-bottom-30 ">
						<div class="  margin-top-40 fs24  padding-bottom-15">
							是否连载
						</div>
						<el-form ref="form" :model="form">
							<el-form-item label="">
								<el-radio-group v-model="form.is_continued">
									<el-radio :label="0">连载</el-radio>
									<el-radio :label="1">完结</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-form>
					</div>
					<div class=" padding-bottom-30 ">
						<div class="  margin-top-40 fs24  padding-bottom-15">
							付费设置
						</div>
						<el-form ref="form" :model="form">
							<el-form-item label="">
								<el-radio-group v-model="form.is_free">
									<el-radio :label="0">付费</el-radio>
									<el-radio :label="1">免费</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-form>
						<div class="line-eee padding-lr-20 padding-tb-8 flex justify-between radius-8"
							v-if="form.is_free==0">
							<input type="" name="" id="" class="" v-model='form.money' placeholder="请输入阅读费用" />
							<span>￥</span>
						</div>
					</div>
					<div class=" margin-top-70 margin-bottom-20 padding-bottom-60 flex align-center justify-center">
						<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
							@click="submitForm">下一步</span>
					</div>

				</div>



			</div>
		</form>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import axios from 'axios'
	import { API_BASE_URL } from '@/config/index.js'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'editBook',
		components: {
			bottom,
			top
		},
		data() {
			return {
				upload_cover:'',//手动上传的封面路径
				fullscreenLoading:false, //全局loading
				uploadFiles: [], //上传 的文件
				current_cover: '-1', //封面图索引
				BaseUrl: this.imgUrl,
				coverimglist: [], //封面列表
				img_show: true, //图片列表的显示
				content: '', //富文本内容
				editorOption: {},
				exchange_code: '', //兑换码
				cover_type: 1, //1  免费    2 定制    0 手动上传
				form: {
					title: '', //标题
					body:'', //简介
					is_free: 0, //是否免费
					money: '', //阅读费用
					file: '',
					thumb: '' ,//封面id
					is_continued:0
				},
				servive_mobile:'' //客服电话
			}
			// editorOption里是放图片上传配置参数用的，例如：
			// action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址
			// methods: 'post',  // 必填参数 图片上传方式
			// token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
			// name: 'upload_file',  // 必填参数 文件的参数名
			// size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
			// accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式
		},
		mounted() {
			this.getCover();
			this.getServiceMobil() //获取客服电话
		},
		methods: {
			// 上传封面成功回调
			handleAvatarSuccess(res, file) {
				console.log(res.data);
				this.upload_cover = res.data.data.file;
				this.form.thumb = res.data.data.fileId;
			},
			// 获取电子书详情
			getBookDetail(cover_list) {
				this.ajax('post', '/v1/613afc809f5e3', {
					article_id: this.$route.query.aid
				}, res => {
					if (res.code == 1) {
						let data = res.data;
						this.form.title = data.title;
						this.form.body = data.body;
						this.form.is_free = data.is_free;
						this.form.money = Number(data.money);
						this.cover_type = data.thumb_type==0 ? 3 : data.thumb_type;
						if(this.cover_type ==3 ){
							this.upload_cover = data.thumb[0];
							this.form.thumb = data.thumb_id
						}else{
							let thumb = data.thumb[0]
							let cover_index  = cover_list.findIndex(item=>{
								return item.img_url==thumb
							});
							this.current_cover = cover_index;
							this.form.thumb = cover_list[cover_index].img;
						}
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 选中封面
			checked_cover(item, index) {
				if (this.current_cover == index) return;
				this.form.thumb = item.img;
				this.current_cover = index;
			},
			// 封面是否收费切换
			coverChange(e) {
				this.cover_type = e;
				if (e == 2) {
					this.img_show = false
				} else if(e==1) {
					this.exchange_code = '';
					this.img_show = true
					this.getCover()
				}
			},
			// 确认兑换封面
			coverInput_confirm() {
				if (!this.exchange_code) {
					this.$message.error('请先输入兑换码');
					return
				}
				this.getCover()
			},
			// 上传电子书回调
			handleChange(file, fileList) {
				if(file.size==0){
					this.$message.error('文件内容不能为空，请重新上传');
					this.$refs['my-upload'].clearFiles();
					return;
				}
				this.uploadFiles = fileList;
				let txtFiles = URL.createObjectURL(file.raw);
				this.fileList = fileList.slice(-3);
				this.form.file = file;
				// console.log('文件', file);
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.form.file = ''
			},
			// submitForm() {
			// 	this.$router.push({
			// 		path: '/memberDate'
			// 	})
			// 	console.log(this.$refs.text.value)
			// },
			// 获取封面
			// type: 1免费的 2定制的
			getCover() {
				this.ajax('post', '/v1/616a9943b4f87', {
					exchange_code: this.exchange_code,
					type: this.cover_type
				}, res => {
					if (res.code == 1) {
						this.coverimglist = res.data;
						this.img_show = true;
						if (res.data.length == 0 && !this.form.is_free) {
							this.$message.info('兑换码错误')
						};
						if(this.$route.query.aid){
							this.getBookDetail(res.data)
						}
					} else {
						this.$message.error(res.msg);
					}
				}, err => {
					console.log(err);
				})

			},
			// 获取客服电话
			getServiceMobil() {
				this.ajax('post', '/v1/61970d3dbed78', {}, res => {
					if (res.code == 1) {
						this.servive_mobile = res.data.servive_mobile
					} else {
						
					}
				}, err => {
					console.log(err);
				})
			
			},
			// 创建电子书
			submitForm() {
				let {
					title,
					body,
					is_free,
					thumb,
					file,
					money,
					is_continued
				} = this.form;
				if (!title) {
					this.$message.error('请输入标题');
					return;
				}
				if (!body) {
					this.$message.error('请输入简介');
					return;
				}
				if (title.length>10) {
					this.$message.error('标题长度不能超过10个字符');
					return;
				}
				if (!thumb) {
					this.$message.error('请先选择或上传电子书封面');
					return;
				}
				
				if (is_free == 0 && !money) {
					this.$message.error('请输入收费金额');
					return;
				};
				if(money && Number(money)>50){
					this.$message.error('收费电子书金额不能高于50元哦');
					return;
				};
				// 	thumb_type //收费封面 1：是   0：否
				this.form.thumb_type = this.cover_type == 2 ? 1 : 0;
				// this.form.file = this.form.file;
				let formData = new FormData();
				formData.append("thumb_type",this.form.thumb_type);
				// formData.append("file", this.form.file.raw);
				formData.append("title", this.form.title);
				formData.append("body", this.form.body);
				formData.append("is_free", this.form.is_free);
				formData.append("money", this.form.money || 0);
				formData.append("thumb", this.form.thumb);
				formData.append("is_continued", this.form.is_continued);
				formData.append("is_upload", 1); //章节上传1  整书上传2
				let requestUrl = '';
				this.fullscreenLoading  = true;
				if(this.$route.query.aid){
					formData.append("id", this.$route.query.aid);
					requestUrl = API_BASE_URL+"/v1/613c01b1ed24b"
				}else{
					requestUrl = API_BASE_URL+"/v1/6184e956b344b"
				}
				axios.post(requestUrl, formData, {
					headers: {
						'user-token': localStorage.getItem('user_token') || 0
					}
				}).then(res=> {
					this.fullscreenLoading  = false;
					if(res.data.code==1){
						this.$message.success(res.data.msg);
						this.$router.push({
							path:'/addSection',
							query:{
								aid:res.data.data.id,
								is_reset:0
							}
						})
					}else{
						this.$message.success(res.data.msg);
					}
				}).catch(err=>{
					this.$message.error('服务器错误，请重试');
				})

			},
		}
	}
</script>

<style scoped>
	.edit-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.cover_box {
		position: relative;
	}
	.coverDiv{
		padding: 10px;
		background-color: #f3f3f3;
		border-radius: 10px;
		border: 1px solid #ddd;
		cursor: pointer;
		display: inline-block;
	}
	.coverDiv_inner{
		width: 135px;
		height: 190px;
	}
	.el-icon-upload{
		font-size: 50px;
	}
	.checked_img {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 40px;
		height: 40px;
	}

	.upload-demo {
		width: 50%;
	}

	.contents {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}

	.myQuillEditor {
		height: 400px;
	}

	/deep/.el-form-item__label {
		padding-right: 30px;
	}

	/deep/.el-radio__inner {
		width: 20px;
		height: 20px;
	}

	.el-form-item {
		display: flex;
	}

	.code_input {
		border-radius: 4px 0 0 4px;
		height: 42px;
	}

	.confirm-btn {
		border-radius: 0px 4px 4px 0px;
		height: 42px;
		line-height: 42px;
		border: 1px solid #E54F42;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #E54F42;
		background: #E54F42;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #333333;
	}
</style>
